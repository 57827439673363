<template>
	<div style="border: 1px solid #d8deea;padding-bottom:20px;margin-top: 20px;">
		<div class="title_top">
			<div>
				<span>
					<span>
						基础题型:{{questionListData.questionOutVo.featureCode|featureCode(that)}}
					</span>
					<span @click="QuestionTypeEntry(questionListData)">
						<a>
							{{$store.state.teachlanguagedata.questiontype}}:{{questionListData.questionOutVo.featureTypeName}}
						</a>
					</span>
					
				</span>
			</div>
		</div>
		<div class="title_con">
				<div class="title_con_content" style="margin-top: 20px;">
					<span v-html="questionListData.questionOutVo.content"></span>
				</div>
				<div class="title_con_con" style="margin-top: 20px;" v-if="!questionListData.childrenList">
					{{$store.state.teachlanguagedata.answeranalysis}}:
					<span style="background: #f3f5f9;padding: 5px 10px;" @click="isExpandClick()">}</span>
					<el-button @click="entry(questionListData)">修改答案解析</el-button>
					<section style="background: #f3f5f9;padding: 5px 10px;margin-top: 20px;overflow: auto;" :style="{'display':isExpandClickShow? '':'none'}" class="question-answer">
						<div style="margin-top: 10px;">
							<span class="question-label">{{$store.state.teachlanguagedata.Referenceanswer}}：</span>
							<span class="question-text" v-for="item in questionListData.questionOutVo.answerList"><span
									v-html="item.value"></span></span>
						</div>
						<div>
							<span class="question-label" style="display:flex">
								<span style="white-space: nowrap;">{{$store.state.teachlanguagedata.Topicanalysis}}：</span>
								<span v-html="questionListData.questionOutVo.analysis"></span>
							</span>
						</div>
					</section>
				</div>
				<div class="title_con_foot" style="margin-top: 20px;" v-if="!questionListData.childrenList&&questionListData.questionOutVo.catalogList&&questionListData.questionOutVo.catalogList.length>0" @click="eject()">
					{{$store.state.teachlanguagedata.knowledgepoints}}:<span v-for="item in questionListData.questionOutVo.catalogList" v-html="item.name"></span>
				</div>
				<div class="title_con_foot" style="margin-top: 20px;" v-if="!questionListData.childrenList&&questionListData.questionOutVo.thinkModelLabelList&&questionListData.questionOutVo.thinkModelLabelList.length>0" @click="eject()">
					{{$store.state.teachlanguagedata.problemSolvingModel}}:<span v-for="item in questionListData.questionOutVo.thinkModelLabelList" v-html="item.name"></span>
				</div>
				<div class="title_con_foot" style="margin-top: 20px;" v-if="!questionListData.childrenList&&questionListData.questionOutVo.solveModelLabelList&&questionListData.questionOutVo.solveModelLabelList.length>0" @click="eject()">
					{{$store.state.teachlanguagedata.thinkingProcess}}:<span v-for="item in questionListData.questionOutVo.solveModelLabelList" v-html="item.name"></span>
				</div>
		</div>
		<div v-if="questionListData.childrenList">
			<div v-for="(quesTionListData,index) in questionListData.childrenList">
				<div class="title_top">
					<div>
						<span>
							<span>
								{{quesTionListData.questionOutVo.index}} 基础题型:{{questionListData.questionOutVo.featureCode|featureCode(that)}}
							</span>
							<span @click="QuestionTypeEntry(questionListData,quesTionListData)">
								<a>
									{{$store.state.teachlanguagedata.questiontype}}:{{quesTionListData.questionOutVo.featureTypeName}}
								</a>
							</span>
						</span>
					</div>
				</div>
				<div class="title_con">
						<div class="title_con_content" style="margin-top: 20px;">
							<span v-html="quesTionListData.questionOutVo.content"></span>
						</div>
						<div class="title_con_con" style="margin-top: 20px;">
							{{$store.state.teachlanguagedata.answeranalysis}}:
							<span style="background: #f3f5f9;padding: 5px 10px;" @click="isExpandClick()">}</span>
							<el-button @click="entry(questionListData,quesTionListData)">修改答案解析</el-button>
							<section style="background: #f3f5f9;padding: 5px 10px;margin-top: 20px;overflow: auto;" :style="{'display':isExpandClickShow? '':'none'}" class="question-answer">
								<div style="margin-top: 10px;">
									<span class="question-label">{{$store.state.teachlanguagedata.Referenceanswer}}：</span>
									<span class="question-text" v-for="item in quesTionListData.questionOutVo.answerList"><span
											v-html="item.value"></span></span>
								</div>
								<div>
									<span class="question-label" style="display:flex">
										<span style="white-space: nowrap;">{{$store.state.teachlanguagedata.Topicanalysis}}：</span>
										<span v-html="quesTionListData.questionOutVo.analysis"></span>
									</span>
								</div>
							</section>
						</div>
					<!-- 	<div class="title_con_con" style="margin-top: 20px;">
							批改数据:
							<span style="background: #f3f5f9;padding: 5px 10px;" @click="isExpandClicks()">}</span>
							<section style="background: #f3f5f9;padding: 5px 10px;margin-top: 20px;overflow: auto;" :style="{'display':isExpandClickShows? '':'none'}" class="question-answer">
								<div class="question-text" v-for="item in quesTionListData.questionOutVo.answerList">
									<Revision ref="RevisionRef" :RevisionData="item" :RevisionSerial="item.serial" :questionListData="questionListData" :questionListId="quesTionListData.id" :key="item.id"></Revision>
								</div>
							</section>
						</div> -->
						<div class="title_con_foot" style="margin-top: 20px;" v-if="quesTionListData.questionOutVo.catalogList&&quesTionListData.questionOutVo.catalogList.length>0"  @click="eject(index)">
							{{$store.state.teachlanguagedata.knowledgepoints}}:<span v-for="item in quesTionListData.questionOutVo.catalogList" v-html="item.name"></span>
						</div>
						<div class="title_con_foot" style="margin-top: 20px;" v-if="quesTionListData.questionOutVo.thinkModelLabelList&&quesTionListData.questionOutVo.thinkModelLabelList.length>0" @click="eject(index)">
							{{$store.state.teachlanguagedata.problemSolvingModel}}:<span v-for="item in quesTionListData.questionOutVo.thinkModelLabelList" v-html="item.name"></span>
						</div>
						<div class="title_con_foot" style="margin-top: 20px;" v-if="quesTionListData.questionOutVo.solveModelLabelList&&quesTionListData.questionOutVo.solveModelLabelList.length>0" @click="eject(index)">
							{{$store.state.teachlanguagedata.thinkingProcess}}:<span v-for="item in quesTionListData.questionOutVo.solveModelLabelList" v-html="item.name"></span>
						</div>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="ejectshow" width="30%">
			<div class="displayFlex flexWrapWrap" v-if="ejectshow">
				<!-- 知识点 -->
				<div class="over_hidee">
					<h4 style="text-align: left">
						<span>{{$store.state.teachlanguagedata.keyKnowledge}}</span>
						<span style="color: #66b1ff;" @click="append('keyPoint','add')">+</span>
					</h4>
					<div>
						<el-tree :data="knowledges" ref="knowledges" :render-content="renderContent" :default-checked-keys="knowledgesChecked" show-checkbox node-key="id" :props="defaultProps">
						</el-tree>
					</div>
				</div>
				<!-- 解题模型 -->
				<div class="over_hidee">
					<h4 style="text-align: left">
						<span>{{$store.state.teachlanguagedata.problemSolvingModel}}</span>
						<span style="color: #66b1ff;" @click="append('thinkModel','add')">+</span>
					</h4>
					<div>
						<el-tree :data="thinkingMethods" :render-content="renderContent" show-checkbox node-key="id" ref="thinkingMethods" :default-checked-keys="thinkingMethodsChecked" :props="defaultProps">
						</el-tree>
					</div>
				</div>
				<!-- 思维方法 -->
				<div class="over_hidee">
					<h4 style="text-align: left" @click="">
						<span>{{$store.state.teachlanguagedata.thinkingProcess}}</span>
						<span style="color: #66b1ff;" @click="append('analyticMethod','add')">+</span>
					</h4>
					<div>
						<el-tree :data="solvingModels" show-checkbox node-key="id" ref="solvingModels" :default-checked-keys="solvingModelsChecked" :props="defaultProps" :render-content="renderContent">
						</el-tree>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="ejectshow = false"> {{$store.state.teachlanguagedata.cancel}}</el-button>
				<el-button type="primary" @click="catalogSave()">{{$store.state.teachlanguagedata.confirm}}</el-button>
			</span>
		</el-dialog>
		<!-- 模型 -->
		<el-dialog :title="modify.id?'修改解题模型':'新增解题模型'" :visible.sync="Modelshow" width="30%">
			<input  v-model="nameAdd"/>
			<el-button @click="Modelshow = false"> {{$store.state.teachlanguagedata.cancel}}</el-button>
			<el-button type="primary" @click="modifySave(modify)">{{$store.state.teachlanguagedata.confirm}}</el-button>
			<!-- <el-button type="primary" v-if="modify.id" @click="catalogDelete(modify)">删除</el-button> -->
		</el-dialog>
		<!-- 方法 -->
		<el-dialog :title="modify.id?'修改思维方法':'新增思维方法'" :visible.sync="methodshow" width="30%">
			<input  v-model="nameAdd"/>
			<el-button @click="methodshow = false"> {{$store.state.teachlanguagedata.cancel}}</el-button>
			<el-button type="primary" @click="modifySave(modify)">{{$store.state.teachlanguagedata.confirm}}</el-button>
			<!-- <el-button type="primary" v-if="modify.id" @click="catalogDelete(modify)">删除</el-button> -->
		</el-dialog>
		<!-- 知识 -->
		<el-dialog :title="modify.id?'修改知识点':'新增知识点'" :visible.sync="knowledgeshow" width="30%">
			<input  v-model="nameAdd"/>
			<el-button @click="knowledgeshow = false"> {{$store.state.teachlanguagedata.cancel}}</el-button>
			<el-button type="primary" @click="modifySave(modify)">{{$store.state.teachlanguagedata.confirm}}</el-button>
			<el-button type="primary" v-if="modify.id" @click="catalogDelete(modify)">删除</el-button>
		</el-dialog>
		<!-- 修改答案解析 -->
		<el-dialog :visible.sync="AnswerModificationShow" width="30%">
			<AnswerModification v-if="AnswerModificationShow" :father="father" :son="son" :TitleNameShow="TitleNameShow"></AnswerModification>
		</el-dialog>
		<!-- 修改题型 -->
		<el-dialog :visible.sync="TitleNameShow" width="30%">
			<AnswerModification v-if="TitleNameShow" :father="father" :son="son" :TitleNameShow="TitleNameShow"></AnswerModification>
		</el-dialog>
	</div>
</template>
<script>
	import { saveLabelRelation,submitPenParamsToKafka,questionSaveBase,catalogsave,labelsave,bindMatchRelation,catalogdelete ,queryListByDictValue} from "../../api/base.js"
	import baseJson from '../../utils/base.json'
	import Revision from "../Revision.vue"
	import AnswerModification from "../AnswerModification.vue"
	export default {
		name: 'App',
		data() {
			return {
				that:this,
				question_types:'',
				father:'',
				son:'',
				AnswerModificationShow:false,
				TitleNameShow:false,
				modify:'',
				nameAdd:'',
				Modelshow:false,
				methodshow:false,
				knowledgeshow:false,
				questionCreateVo:{
					id:'',
					catalogList:[],
					labelList:[],
					childrenList: []
				},
				knowledges:[],//知识点
				knowledgesChecked:[],//知识点默认
				thinkingMethods:[],//解题模型
				thinkingMethodsChecked:[],//解题模型默认
				solvingModels:[],//思维方法
				solvingModelsChecked:[],//思维方法默认
				isExpandClickShow:false,
				isExpandClickShows:false,
				ejectshow:false,
				ejectBigTopic:null,
				NewData:'',
				OldData:'',
				ToKafkaData:'',
				defaultProps: {
					children: 'children',
					label: 'name'
				},
			}
		},
		components: {
			Revision,
			AnswerModification
		},
		watch:{
			
		},
		filters: {
			// 时间戳处理
			featureCode(value,that) {
				for (let i=0;i<that.question_types.length;i++) {
					if(value==that.question_types[i].valueCode){
						return that.question_types[i].valueName
					}
				}
			}
		},
		props: ['questionListData'],
		mounted() {
			this.getkquestiontype()
		},
		methods: {
			// 题型
			getkquestiontype() {
				// var subjectId = JSON.parse(localStorage.getItem("resourcesubjectId"))
				// getKquestiontype({subjectId})
				queryListByDictValue('dictValue=' + 'question_feature_code')
					.then(response => {
						this.question_types = response.data.result
					})
			},
			//修改弹出框
			entry(father,son){
				this.father=JSON.parse(JSON.stringify(father))
				if(son){
					this.son=JSON.parse(JSON.stringify(son))
				}else{
					this.son=''
				}
				this.AnswerModificationShow=true
			},
			//题型修改
			QuestionTypeEntry(father,son){
				this.father=JSON.parse(JSON.stringify(father))
				if(son){
					this.son=JSON.parse(JSON.stringify(son))
				}else{
					this.son=''
				}
				this.TitleNameShow=true;
			},
			//树型结构删除添加
			renderContent(h, {node,data,store}) {
				return (
				<span class = "custom-tree-node" >
				<span> {node.label} </span> <span ><el-button style = "margin-left:20px;font-size:20px;" size = "mini" type = "text" on-click = {() => this.append(data)} > + </el-button> </span> </span>);
			},
			//append知识点修改删除新增
			//modify 传入参数 ,add,传入状态 
			append(modify,stutes){
				this.modify=modify
				if(stutes=='add'){
					this.nameAdd=''
					if(modify=="thinkModel"){
						this.Modelshow=true
					}else if(modify=="analyticMethod"){
						this.methodshow=true
					}else if(modify=="keyPoint"){
						this.knowledgeshow=true
					}
				}else{
					this.nameAdd=this.modify.name
					if(modify.type=="thinkModel"){
						this.Modelshow=true
					}else if(modify.type=="analyticMethod"){
						this.methodshow=true
					}else if(modify.type=="keyPoint"){
						this.knowledgeshow=true
					}
				}
			},
			modifySave(modify,type){
				if(modify.id){
					let data=JSON.parse(JSON.stringify(modify))
					data.name=this.nameAdd
					if(modify.type=="keyPoint"){
						this.Catalogsave(data)
					}else{
						data.subjectId=JSON.parse(localStorage.getItem("textbooklist")).subjectId
						this.labelSave(data)
					}
				}else{
					let data={
						subjectId: JSON.parse(localStorage.getItem("textbooklist")).subjectId,
						type: modify,
						name: this.nameAdd,
					}
					if(modify=="keyPoint"){
						data.editionId=JSON.parse(localStorage.getItem("textbooklist")).editionId
						data.textbookId=JSON.parse(localStorage.getItem("textbooklist")).textbookId
						data.level=1
						data.parentId=0
						this.Catalogsave(data)
					}else{
						this.labelSave(data)
					}
				}
			},
			catalogDelete(data){
				catalogdelete(data).then(res=>{
					if(res.data.code=='0000'){
						this.$message({
							message:res.data.msg,
							type: 'success'
						});
						this.watchParentData()
					}
				})
			},
			watchParentData(){
				location.reload();
			},
			Catalogsave(data){
				//有问题
				let that = this
				catalogsave(data).then(res=>{
					if(res.data.code=='0000'){
						let chapterId = that.$store.state.clickHomeworkGetChapter
						// let chapterId=this.$Download.getQuestionSet(JSON.parse(localStorage.getItem("teachQuestionSetInformation")).resourceRelationList,'chapter')
						let data2 = {
							"mainId": chapterId,
							"mainKind": "chapter",
							"relationId": res.data.result.id,
							"relationKind": "keyPoint",
						}
						bindMatchRelation(data2).then(bindMatchRelationres => {
							if(bindMatchRelationres.data.code=='0000'){								
								location.reload();
								// knowledges
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// this.watchParentData()
							}
						})
					}
				})
			},
			labelSave(data){
				labelsave(data).then(res=>{
					if(res.data.code=='0000'){
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
						this.watchParentData()
					}
				})
			},
			catalogSave(){
				let solvingModels=this.$refs.solvingModels.getCheckedNodes();
				let thinkingMethods=this.$refs.thinkingMethods.getCheckedNodes();
				let catalogList=this.$refs.knowledges.getCheckedNodes();
				let labelList=[...solvingModels, ...thinkingMethods];
				if(this.questionListData.childrenList){
					this.questionCreateVo.id=this.questionListData.id
					this.questionCreateVo.catalogList=[]
					this.questionCreateVo.labelList=[]
					this.questionCreateVo.childrenList=this.getSmallQuestions(this.questionListData.childrenList)
					this.questionCreateVo.childrenList[this.ejectBigTopic].labelList=labelList
					this.questionCreateVo.childrenList[this.ejectBigTopic].catalogList=catalogList
					this.SaveLabelRelation(this.questionCreateVo)
				}else{
					this.questionCreateVo.id=this.questionListData.id

					this.questionCreateVo.catalogList=catalogList
					//知识点，解题模型 思维方式  选择过后的数组集合
					this.questionCreateVo.labelList=labelList
					this.SaveLabelRelation(this.questionCreateVo)
				}
			},
			//小题重新赋值
			getSmallQuestions(data){
				let arry=[]
				data.forEach(item=>{
					let canshu={
						id:'',
						catalogList:[],
						labelList:[],
						childrenList: [],
					}
					canshu.id=item.id
					canshu.catalogList=item.questionOutVo.catalogList
					canshu.labelList=[...item.questionOutVo.solveModelLabelList, ...item.questionOutVo.thinkModelLabelList]
					arry.push(JSON.parse(JSON.stringify(canshu)))
				})
				return arry
			},
			//保存更新
			SaveLabelRelation(data){
				saveLabelRelation(data).then(res=>{
					if(this.questionListData.childrenList){
						this.questionListData.childrenList[this.ejectBigTopic].questionOutVo.catalogList=this.$refs.knowledges.getCheckedNodes();
						this.questionListData.childrenList[this.ejectBigTopic].questionOutVo.solveModelLabelList=this.$refs.solvingModels.getCheckedNodes();
						this.questionListData.childrenList[this.ejectBigTopic].questionOutVo.thinkModelLabelList=this.$refs.thinkingMethods.getCheckedNodes();
					}else{
						this.questionListData.questionOutVo.catalogList=this.$refs.knowledges.getCheckedNodes();
						this.questionListData.questionOutVo.solveModelLabelList=this.$refs.solvingModels.getCheckedNodes();
						this.questionListData.questionOutVo.thinkModelLabelList=this.$refs.thinkingMethods.getCheckedNodes();
					}
					this.ejectshow=false
				}).catch(res=>{
					
				})
			},
			isExpandClick(){
				this.isExpandClickShow=!this.isExpandClickShow
			},
			isExpandClicks(){
				this.isExpandClickShows=!this.isExpandClickShows
			},
			eject(index){
				this.ejectBigTopic=index
				this.ejectshow=true;
				this.getkthinkingknowledgeData=this.$parent.getkthinkingknowledge()
				this.knowledges=this.getkthinkingknowledgeData.knowledges
				this.thinkingMethods=this.getkthinkingknowledgeData.thinkingMethods
				this.solvingModels=this.getkthinkingknowledgeData.solvingModels
				if(this.questionListData.childrenList){
					this.knowledgesChecked=this.DefaultSelectionId(this.questionListData.childrenList[this.ejectBigTopic].questionOutVo.catalogList)
					this.thinkingMethodsChecked=this.DefaultSelectionId(this.questionListData.childrenList[this.ejectBigTopic].questionOutVo.thinkModelLabelList)
					this.solvingModelsChecked=this.DefaultSelectionId(this.questionListData.childrenList[this.ejectBigTopic].questionOutVo.solveModelLabelList)
				}else{
					this.knowledgesChecked=this.DefaultSelectionId(this.questionListData.questionOutVo.catalogList)
					this.thinkingMethodsChecked=this.DefaultSelectionId(this.questionListData.questionOutVo.thinkModelLabelList)
					this.solvingModelsChecked=this.DefaultSelectionId(this.questionListData.questionOutVo.solveModelLabelList)
				}
			},
			DefaultSelectionId(data){
				let arry=[]
				data.forEach((item)=>{
					let canshu=''
					canshu=item.id
					arry.push(JSON.parse(JSON.stringify(canshu)))
				})
				return arry
			},
		}
	}
</script>
<style scoped="scoped">
	.zhanwushujiu{
		width: 100%;
		height: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	.title {
		border: 1px solid #d8deea;
		margin-top: 20px;
		padding-bottom: 20px;
	}
	
	.title_con_content>>>img {
		max-width: 100%;
	}
	
	.title_con_content {
		color: #13264d;
		font-weight: 600;
		font-size: 18px;
		line-height: 27px;
	}
	
	.title_con_con {
		margin-left: 20px;
	}
	
	.title_con_foot span {
		height: 28px;
		line-height: 28px;
		padding: 8px;
		color: #6e7c94;
		background-color: #edeff2;
		margin-left: 8px;
		border-radius: 2px;
	}
	
	.title_top {
		height: 56px;
		background-color: #f3f5f9;
		padding: 0 20px;
		line-height: 56px;
		display: flex;
		justify-content: space-between;
	}
	 .title:nth-of-type(1) {
		 margin-top: 0;
	 }
	.title_con {
		padding: 12px 20px 0 20px;
		text-align: left;
	}
</style>
