<template>
	<div style="background: #fff;padding: 20px;overflow: auto;">
		<ComponentPage v-for="item in QuestionTreeList" v-if="QuestionTreeListShow" :questionListData="item"></ComponentPage>
	</div>
</template>

<script>
import ComponentPage from '../../components/ComponentPage/QuestionTreeListIndex.vue'
import {
		queryQuestionTreeList
	} from '../../api/Correct.js'
	import {thinkqueryList,getKnowledge} from "../../api/base.js"
	export default {
		name: 'ModifyTheTitle',
		data() {
			return {
				QuestionTreeListShow:false,
				setId:JSON.parse(localStorage.getItem("teachsetId")),
				QuestionSetInformation:JSON.parse(localStorage.getItem("teachQuestionSetInformation")),
				QuestionTreeList:[]
			}
		},
		components: {
			ComponentPage
		},
		watch: {
			
		},
		created(){
			
		},
		mounted() {
			this.getkthinking()
			this.getksolvingmodel()
			this.GetqueryQuestionTreeList({"setId": this.setId,"needLabel":true})
		},
		methods: {
			// 获取知识点 思维方法 解题模型 参数
			getkthinkingknowledge(){
				let data={
					thinkingMethods:this.thinkingMethods,
					solvingModels:this.solvingModels,
					knowledges:this.knowledges,
				}
				return data
			},
			// 解题模型
			getkthinking() {
				thinkqueryList({
						"currPage": 1,
						"pageSize": 100,
						"subjectId":JSON.parse(localStorage.getItem("teachsubjectId")),
						"type": "thinkModel"
					})
					.then(response => {
						this.thinkingMethods = response.data.result.list
					})
			},
			//思维方法
			getksolvingmodel() {
				thinkqueryList({
						"currPage": 1,
						"pageSize": 100,
						"subjectId": JSON.parse(localStorage.getItem("teachsubjectId")),
						"type": "analyticMethod"
					})
					.then(response => {
						this.solvingModels = response.data.result.list
					})
			},
			//获取知识点
			getknowledge() {
				let chapterId = this.$store.state.clickHomeworkGetChapter
				// var chapterId = this.$Download.getQuestionSet(this.QuestionSetInformation.resourceRelationList,'questionSet')
				let data = {
					"currPage": 1,
					"mainId": chapterId,
					"pageSize": 10000,
					"relationKind": "keyPoint"
				}
				getKnowledge(data).then(response => {
					this.knowledges = response.data.result.list
				})
			},
			GetqueryQuestionTreeList(data){
				queryQuestionTreeList(data).then(res=>{
					this.QuestionTreeList=res.data.result
					this.QuestionTreeListShow=true
					this.getknowledge()
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.height_main{
		height:auto;
	}
</style>
