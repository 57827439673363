<template>
	<div style="text-align: left;margin-top: 20px;">
		<div>
			<span>解析</span>
			<quill-editor style="margin-top: 20px;" v-model="contnet" ref="richAnalysis" :options="options">
			</quill-editor>
		</div>
	</div>
</template>

<script>
	import {
		quillEditor
	} from "vue-quill-editor";
	import baseJson from '../../utils/base.json'
	export default {
		name: 'App',
		data() {
			return {
				contnet:'',
				options: JSON.parse(JSON.stringify(baseJson.options)),
			}
		},
		components: {
			quillEditor,
		},
		props: ["father","son"],
		mounted() {
			if(this.son==''){
				this.contnet=this.father.questionOutVo.analysis
			}else{
				this.contnet=this.son.questionOutVo.analysis
			}
		},
		methods: {
		}
	}
</script>

<style>
</style>
