<template>
	<div>
		<div v-if="TitleNameShow">
			<span style="display: flex;justify-content: space-between;align-items: center;margin-top: 20px;" v-if="son&&son.questionOutVo&&son.questionOutVo.featureTypeName">
				<span style="white-space: nowrap;">修改题型:</span>
				<el-input style="width: 220px;" v-model="son.questionOutVo.featureTypeName" />
			</span>
			<span style="display: flex;justify-content: space-between;align-items: center;margin-top: 20px;" v-else>
				<span style="white-space: nowrap;">修改题型:</span>
				<el-input style="width: 220px;" v-model="father.questionOutVo.featureTypeName" />
			</span>
		</div>
		<div v-else>
			<AnswerEntry ref="AnswerEntry" v-if="AnalysisEntryShow" :father="father" :son="son"></AnswerEntry>
			<AnalysisEntry ref="AnalysisEntry" :father="father" :son="son"></AnalysisEntry>
		</div>
		<div style="margin-top: 20px;">
			<el-button @click="preservation()">保存</el-button>
			<el-button>取消</el-button>
		</div>
	</div>
</template>

<script>
	import {
		quillEditor
	} from "vue-quill-editor";
	import baseJson from '../utils/base.json'
	import AnswerEntry from './TitleEntry/AnswerEntry.vue'
	import AnalysisEntry from './TitleEntry/AnalysisEntry.vue'
	import {questionSaveBase} from "../api/base.js"
	export default {
		name: 'App',
		data() {
			return {
				AnalysisEntryShow:true,
				contnet:'',
				featureTypeName:'',
				quesTionCreateVo:JSON.parse(JSON.stringify(baseJson.questionCreateVo)),
				options: JSON.parse(JSON.stringify(baseJson.options)),
			}
		},
		components: {
			quillEditor,AnswerEntry,AnalysisEntry
		},
		props: ["father","son","TitleNameShow"],
		mounted() {
			if(this.son){
				this.ConnectJudgment(this.son)
			}else{
				this.ConnectJudgment(this.father)
			}
		},
		methods: {
			ConnectJudgment(data){
				if(data.questionOutVo.featureCode=="1200001"||data.questionOutVo.featureCode=="1100001"){
					this.AnalysisEntryShow=false
				}else{
					this.AnalysisEntryShow=true
				}
			},
			preservation(){
				if(this.TitleNameShow){
					if(this.son){
						this.checkTopicEntryIntData(this.father,this.son.id)
					}else{
						this.checkTopicEntryIntData(this.father,this.father.id)
					}
				}else{
					let answerList='';
					let analysis=this.$refs.AnalysisEntry.contnet;
					if(this.$refs.AnswerEntry){
						answerList=this.$refs.AnswerEntry.questionCreateVo.answerList
					}
					if(this.son){
						this.father.childrenList.forEach(item=>{
							if(item.id==this.son.id){
								item.questionOutVo.analysis=analysis
								item.questionOutVo.answerList=answerList
							}
						})
						this.checkTopicEntryIntData(this.father,this.son.id)
					}else{
						this.father.questionOutVo.analysis=analysis
						this.father.questionOutVo.answerList=answerList
						this.checkTopicEntryIntData(this.father,this.father.id)
					}
				}
			},
			// 修改保存
			QuestionSaveBase(data){
				questionSaveBase(data).then(res=>{
					this.$message("修改成功")
					location.reload();
				})
			},
			// 组合数据
			checkTopicEntryIntData(data,id,serial){
				var canshu=this.checkTopicEntryIntDataSon(data)
				if(data.childrenList&&data.childrenList.length>0){
					canshu.childrenList=[]
					data.childrenList.forEach((item,index)=>{
						let childrenListdata=this.checkTopicEntryIntDataSon(item)
						canshu.childrenList.push(childrenListdata)
					})
				}
				this.QuestionSaveBase(canshu)
			},
			// 组合数据
			checkTopicEntryIntDataSon(data){
				let canshu=JSON.parse(JSON.stringify(this.quesTionCreateVo))
				if(this.son.questionOutVo&&this.son.questionOutVo.id==data.questionOutVo.id){
					data.questionOutVo.featureTypeName=this.son.questionOutVo.featureTypeName
				}
				this.identicalAssignment(canshu,data.questionOutVo)
				canshu.id=data.id
				canshu.serial=data.serial
				canshu.labelList=data.questionOutVo.thinkModelLabelList.concat(data.questionOutVo.solveModelLabelList)
				delete canshu.answerMarkAreaList
				delete canshu.questionMarkAreaList
				return canshu
			},
			//相同对象快速赋值
			identicalAssignment(obj1, obj2) {
				Object.keys(obj1).forEach(key => {
					if(obj2[key]){
						obj1[key] = obj2[key]
					}
				})
				return obj1
			}
		}
	}
</script>

<style>
</style>
