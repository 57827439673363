<template>
	<div>
		<span style="margin-right: 20px;" v-if="!RevisionStatues"> {{$store.state.teachlanguagedata.Selects}}{{item.serial}}{{$store.state.teachlanguagedata.Empty}} ID{{item.id}}</span>
		<span style="margin-right: 20px;" v-else> {{$store.state.teachlanguagedata.Selects}}{{RevisionStatues}}{{$store.state.teachlanguagedata.Empty}} 扩展答案</span>
		<span>
			<span>请选择批改方式：</span>
			<!-- 这里JSON.parse(item.valueCode) ，转成JSON.parse有问题，不影响先放着-->
			<el-select clearable filterable v-model="valueCode" placeholder="请选择文本" style="width: 220px;" @change="IdentificationResultsShow=true">
			  <el-option v-for="item in custom_merger" :key="item.id" :label="item.valueName+' 识别内容：'+JSON.parse(item.valueCode).customContent" :value="item.valueCode"/>
			</el-select>
		</span>
		<!-- <el-button @click="copyContent(item)">复制</el-button>
		<el-button @click="paste()">粘贴</el-button> -->
		<el-button style="margin: 0 20px;"
			@click="checkTopicEntryIntData(questionListData,questionListId,item.serial)">修改批改数据</el-button>
		<el-button  @click="QueryPlanUserMaxRecords(questionListId,item.serial)" style="margin-right: 20px;">重新批改
		</el-button>
		<!-- <el-button @click="QueryPlanUserMaxRecords(questionListId,item.serial,'2')" style="margin-right: 20px;"> -->
			<!-- 批改后的点数据</el-button> -->
		<!-- <span v-if="item.serial==itemSerial&&ToKafkaData">传递参数：{{ToKafkaData.paramsList[0]}}</span> -->
		<span v-if="item.serial==itemSerial&&OldData">批改前结果：{{OldData.auto_result}}</span>
		<span v-if="item.serial==itemSerial&&NewData">批改后结果：{{NewData.auto_result}}</span>
		<div style="text-align: left;">
			<div v-if="dotevent">
				<span>批改数据：</span>
				<span>{{dotevent}}</span>
			</div>
			<div v-for="(item,key) in autoCorrectForDotEventsv1Data">
				<span>{{key}}</span>:<span>{{item}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import { queryListByDictValue,saveLabelRelation,queryPlanUserMaxRecords,queryPlanUserAutoJoinRecords,submitPenParamsToKafka,questionSaveBase,autoCorrectForDotEventsv1} from "../api/base.js"
	import baseJson from '../utils/base.json'
	import baseJs from '../utils/base/base.js'
	export default {
		name: 'App',
		data() {
			return {
				dotevent:'',//批改数据
				custom_merger:[],//默认批改列表
				customMergerValueCode:null,//默认批改方式项
				valueCode:null,
				IdentificationResultsShow:true,
				NewData:'',
				OldData:'',
				ToKafkaData:'',
				contentType:baseJson.contentType,
				languages:baseJson.languages,
				item:{
					contentType:null,
				},
				quesTionCreateVo:JSON.parse(JSON.stringify(baseJson.questionCreateVo)),
				itemSerial:1,
				autoCorrectForDotEventsv1Data:[]
			}
		},
		watch:{
			valueCode(vla){
				let data=JSON.parse(vla)
				if(this.RevisionStatues){
					this.item=this.RevisionDataParent
					this.item.answerExtendList[this.RevisionStatues-1].contentType=data.contentType==''?null:data.contentType
					this.item.answerExtendList[this.RevisionStatues-1].customGrammarId=data.customGrammarId==''?null:data.customGrammarId
					this.item.answerExtendList[this.RevisionStatues-1].customResources=data.customResources==''?null:data.customResources
					this.item.answerExtendList[this.RevisionStatues-1].languages=data.language==''?null:data.language
				
				}else{
					this.item.contentType=data.contentType==''?null:data.contentType
					this.item.customGrammarId=data.customGrammarId==''?null:data.customGrammarId
					this.item.customResources=data.customResources==''?null:data.customResources
					this.item.languages=data.language==''?null:data.language
				}
				console.log(this.valueCode);
			},
			custom_merger(val){
				let that =this
				// console.log(val);
				val.forEach((item,index)=>{
					item.valueCode= item.valueCode+""		
					item.valueCode=baseJs.handleSpecialCharacters(item.valueCode,index)
					that.customMergerValueCode.customResources?"":that.customMergerValueCode.customResources=""
					let data=JSON.stringify(that.customMergerValueCode)
					data=data.slice(1,data.length-1)
					// console.log(data);
					if(!(item.valueCode.indexOf(data)==-1)){
						that.valueCode=item.valueCode
						// console.log(123);
					}
				})
			}
		},
		props: ['parentId','RevisionData','RevisionDataParent','RevisionSerial','questionListData','questionListId','student_id','RevisionStatues'],
		mounted() {
			this.item=this.RevisionData
			this.customMergerValueCode={
				contentType:this.RevisionData.contentType?this.RevisionData.contentType:null,
				language:this.RevisionData.languages?this.RevisionData.languages:null,
				customGrammarId:this.RevisionData.customGrammarId?this.RevisionData.customGrammarId:null,
				customResources:this.RevisionData.customResources?this.RevisionData.customResources:null,
			}
			this.itemSerial=this.RevisionSerial
			// console.log(this.parentId,this.RevisionData,this.RevisionDataParent,this.RevisionSerial,this.questionListData,this.questionListId,this.student_id,this.RevisionStatues)
			this.querylistbydictvalue('custom_merger', 'custom_merger')
			
			this.Mathqunati()
			
		},
		methods: {
			// 公式识别
			Mathqunati() {
				this.$nextTick(function() { //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
					if (this.commonsVariable.isMathjaxConfig) { //判断是否初始配置，若无则配置。
						this.commonsVariable.initMathjaxConfig();
					}
					this.commonsVariable.MathQueue("container");
					this.Mathqunatishow = true
				})
			},
			querylistbydictvalue(n, value) {
				let that =this
			  queryListByDictValue('dictValue=' + n).then(res => {
			    this[value] = res.data.result
				console.log(that.custom_merger);
			  })
			},
			// // 复制
			// copyContent(item){
			// 	localStorage.setItem('teachCorrect',JSON.stringify(item))
			// },
			// 粘贴
			// paste(){
			// 	if(localStorage.getItem("teachCorrect")){
			// 		let data=JSON.parse(localStorage.getItem("teachCorrect"))
			// 		this.item.contentType=data.contentType
			// 		this.item.customGrammarId=data.customGrammarId
			// 		this.item.customResources=data.customResources
			// 		this.item.languages=data.languages
			// 	}else{
			// 		this.$message("没有复制内容")
			// 	}
			// },
			// 修改批改参数
			checkTopicEntryIntData(data,id,serial){
				var canshu=this.checkTopicEntryIntDataSon(data)
				if(data.childrenList&&data.childrenList.length>0){
					canshu.childrenList=[]
					data.childrenList.forEach((item,index)=>{
						let childrenListdata=this.checkTopicEntryIntDataSon(item)
						canshu.childrenList.push(childrenListdata)
					})
				}
				console.log(canshu);
				this.QuestionSaveBase(canshu,id,serial)
			},
			//答题记录结果
			QueryPlanUserMaxRecords(questionId,serial,statues){
				this.itemSerial=serial
				let data={"userId":this.student_id,"planId": JSON.parse(localStorage.getItem('teachplan_id')),"questionId": questionId,"classId":JSON.parse(localStorage.getItem('teachclass_id')),"serial":serial}
				queryPlanUserMaxRecords(data).then(res=>{
					if(statues&&statues=='2'){
						this.NewData=res.data.result[0]
					}else if(statues&&statues=='1'){
						this.OldData=res.data.result[0]
					}else{
						data.userId=this.student_id
						queryPlanUserAutoJoinRecords(data).then(resopen=>{
							let paramsList={paramsList:[]}
							resopen.data.result.forEach(item=>{
								if(item.params){
									paramsList.paramsList.push(item.params)
								}
							})
							this.SubmitPenParamsToKafka(paramsList,questionId,serial)
						})
					}
				})
			},
			SubmitPenParamsToKafka(data,questionId,serial){
				// 消息推送
				this.ToKafkaData=data
				// submitPenParamsToKafka(data).then(res=>{
				// 	this.$message("信息推送成功")
				// })
				if(data&&data.paramsList&&data.paramsList[0]){
					let canshu={ "dotevent": data.paramsList[0]}
					this.dotevent=data.paramsList[0]
					autoCorrectForDotEventsv1(canshu).then(res=>{
						this.autoCorrectForDotEventsv1Data=res.data
					})
				}else{
					this.$message.error("请重新书写这道题")
				}
			},
			// 修改请求
			QuestionSaveBase(data,id,serial){
				if(this.parentId&&this.parentId.length>0){
					data.parentId=this.parentId[0]
				}
				questionSaveBase(data).then(res=>{
					this.$message("修改成功")
					this.QueryPlanUserMaxRecords(id,serial,'1')
				})
			},
			// 快速赋值
			checkTopicEntryIntDataSon(data){
				let canshu=JSON.parse(JSON.stringify(this.quesTionCreateVo))
				this.identicalAssignment(canshu,data.questionOutVo)
				canshu.id=data.id
				canshu.serial=data.serial
				delete canshu.answerMarkAreaList
				delete canshu.questionMarkAreaList
				return canshu
			},
			//相同对象快速赋值
			identicalAssignment(obj1, obj2) {
				Object.keys(obj1).forEach(key => {
					if(obj2[key]){
						obj1[key] = obj2[key]
					}
				})
				return obj1
			}
		}
	}
</script>

<style>
</style>
