<template>
	<!-- 添加答案 -->
	<div>
		<div v-if="TopicOptionsStatues==1">
			<span>答案</span>
			<el-select clearable filterable v-model="TopicOption">
				<el-option v-for="item in TopicOptions" :key="item" :label="item" :value="item">
				</el-option>
			</el-select>
			<el-select clearable filterable v-model="TopicOptionAnswer">
				<el-option v-for="item in TopicOptionSelects" :key="item" :label="item" :value="item">
				</el-option>
			</el-select>
		</div>
		<div v-if="TopicOptionsStatues==2">
			<span>答案</span>
			<quill-editor ref="richAnalysis" v-model="TopicOptionAnswer" :options="options"></quill-editor>
		</div>
		<div v-if="TopicOptionsStatues==3">
			<span>答案</span>
			<quill-editor ref="richAnalysis" v-model="TopicOptionAnswer" :options="options"></quill-editor>
			<el-select clearable filterable v-model="TopicOption">
				<el-option v-for="item in TopicOptions" :key="item" :label="item" :value="item">
				</el-option>
			</el-select>
		</div>
		<!-- 添加答案 -->
		<div>
			<button type="button" @click="AddAnswer()">添加答案</button>
			<button type="button" v-if="questionCreateVo.featureCode=='200001'" @click="AddAnswer('true')">合并答案</button>
			<button type="button" @click="DeleteAnswer()">删除答案</button>
			<span v-for="(items,index) in questionCreateVo.answerList"
				:key="index">{{items.serial}}({{items.value}})</span>
		</div>
	</div>
</template>

<script>
	import {
		quillEditor
	} from "vue-quill-editor";
	import baseJson from '../../utils/base.json'
	export default {
		name: 'App',
		data() {
			return {
				questionCreateVo: '',
				TopicOptionSelects: '',
				TopicOptionAnswer: '',
				TopicOption: '',
				TopicOptions: '',
				TopicOptionsStatues: '1',
				contnet: '',
				answerList: {
					"value": null,
					"customResources": null
				},
				options: JSON.parse(JSON.stringify(baseJson.options)),
			}
		},
		components: {
			quillEditor,
		},
		watch: {
			TopicOption(val) {
				if (val == 'ABC') {
					this.TopicOptionSelects = ['A', 'B', 'C']
				} else if (val == 'ABCD') {
					this.TopicOptionSelects = ['A', 'B', 'C', 'D']
				} else if (val == 'ABCDE') {
					this.TopicOptionSelects = ['A', 'B', 'C', 'D', 'E']
				} else if (val == 'ABCDEFG') {
					this.TopicOptionSelects = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
				} else if (val == 'TF') {
					this.TopicOptionSelects = ['T', 'F']
				} else if (val == 'vx') {
					this.TopicOptionSelects = ['v', 'x']
				} else {
					console.log("error")
				}
			},
		},
		props: ["father", "son"],
		mounted() {
			if (this.son == '') {
				this.getTopicOptionsStatues(this.father.questionOutVo.featureCode)
				this.questionCreateVo = this.father.questionOutVo
			} else {
				this.getTopicOptionsStatues(this.son.questionOutVo.featureCode)
				this.questionCreateVo = this.son.questionOutVo
			}
		},
		methods: {
			//删除答案
			DeleteAnswer(){
				if(this.questionCreateVo.answerList.length>0){
					this.questionCreateVo.answerList.pop()
				}else{
					console.log("error")
				}
			},
			//添加答案
			AddAnswer(show) {
				if (show) {
					this.questionCreateVo.answerList.pop()
					this.answerList.value = this.answerList.value + "$$@@@@" + this.TopicOptionAnswer
					let answerList = JSON.parse(JSON.stringify(this.answerList))
					answerList.customResources = this.getCustomResources()
					this.questionCreateVo.answerList.push(answerList)
					this.TopicOptionAnswer = ''
				} else {
					this.answerList.value = this.TopicOptionAnswer
					let answerList = JSON.parse(JSON.stringify(this.answerList))
					answerList.customResources = this.getCustomResources()
					this.questionCreateVo.answerList.push(answerList)
					this.TopicOptionAnswer = ''
				}
			},
			//处理customResources
			getCustomResources(customResources) {
				let data = ''
				if (this.questionCreateVo.featureCode == '100001' || this.questionCreateVo.featureCode == '200001' || this.questionCreateVo.featureCode == '1000001') {
					data = this.TopicOption + '.'
				} else if (this.questionCreateVo.featureCode == '300001') {
					data = this.TopicOption + '..res'
				}
				return data
			},
			getTopicOptionsStatues(val) {
				if (val == '100001' || val == '200001' || val == '1000001') {
					this.TopicOptions = ['ABC', 'ABCD', 'ABCDE', 'ABCDEFG']
					if (val == '1000001') {
						this.TopicOption = 'ABCDEFG'
					} else {
						this.TopicOption = 'ABCD'
					}
					this.TopicOptionsStatues = 1
				} else if (val == '300001') {
					this.TopicOptions = ['TF', 'vx']
					this.TopicOption = 'TF'
					this.TopicOptionsStatues = 1
				} else if (val == '1200001') {
					this.TopicOption = ''
					this.TopicOptionsStatues = 3
				} else {
					this.TopicOptionsStatues = 2
				}
			},
		}
	}
</script>

<style>
</style>
