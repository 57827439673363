import request from '@/utils/request';
import requesthttps from '@/utils/requesthttps';
//总览 获取使用时长  和平均压力值
export function getDotsTimeAndPressure(data) {
  return requesthttps({
    url: '/get-dotsv3/get_dots_infors',
    method: 'post',
    data,
  });
}
export function getdotsv3(data) {
  return requesthttps({
    url: '/get-dotsv3/get_dots',
    method: 'post',
    data,
  });
}
export function reAutoCorrect(data) {
  return request({
    url: '/re_auto_correct',
    method: 'post',
    data,
  });
}
export function getStudentResult(data) {
  return request({
    url: '/getstudent_result',
    method: 'post',
    data,
  });
}
export function Recorreted(data) {
  return request({
    url: '/recorreted',
    method: 'post',
    data,
  });
}
export function getCorrectionFromPlan(data) {
  return request({
    url: '/get_correction_from_plan',
    method: 'post',
    data,
  });
}
export function queryQuestionTreeList(data) {
  return requesthttps({
    url: '/resourcePlant-web/api/console/markArea/queryQuestionTreeList',
    method: 'post',
    data,
  });
}
export function queryMacSingList(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/pen/queryMacSingList',
    method: 'post',
    data,
  });
}
export function updateSign(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/user/updateSign',
    method: 'post',
    data,
  });
}
export function gotEvaluation(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/planUserResult/gotEvaluation',
    method: 'POST',
    data,
  });
}
export function addUserExtendAnswerList(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/addUserExtendAnswerList',
    method: 'post',
    data,
  });
}
export function submitEvaluation(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/planUserResult/submitEvaluation',
    method: 'post',
    data,
  });
}
export function queryPlanUserJoinDetail(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/queryPlanUserJoinDetail',
    method: 'post',
    data,
  });
}
export function needCorrect(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/submit/needCorrect',
    method: 'post',
    data,
  });
}
export function okCorrected(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/submit/okCorrected',
    method: 'post',
    data,
  });
}
export function queryPlanUserJoinRecords(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/queryPlanUserJoinRecords',
    method: 'post',
    data,
  });
}
export function addExtendAnswerList(data) {
  return requesthttps({
    url: '/resourcePlant-web/api/console/question/addExtendAnswerList',
    method: 'post',
    data,
  });
}
export function auto_correctv1(data) {
  return requesthttps({
    url: '/auto-correct-out/auto_correctv1',
    method: 'post',
    data,
  });
}
export function batch_auxiliary_correction(data) {
  return request({
    url: '/batch_auxiliary_correction',
    method: 'post',
    data,
  });
}
export function queryNameCardSelectList(data) {
  return requesthttps({
    url:
      '/userPlant-web/api/console/planNameCardSelect/queryNameCardSelectList',
    method: 'post',
    data,
  });
}
// 识别结果
export function queryPlanErrorUserAutoJoinRecords(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/queryPlanErrorUserAutoJoinRecords',
    method: 'post',
    data,
  });
}
// 识别结果
export function queryPlanUserAutoJoinRecords(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/queryPlanUserAutoJoinRecords',
    method: 'post',
    data,
  });
}
// 错误重新批改
export function wrongAnswerCorreted(data) {
  return requesthttps({
    url: '/smart-work/wrong_content_answer_correted',
    method: 'post',
    data,
  });
}
